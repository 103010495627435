import React from "react";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { ProvideAuth } from "./app/authHooks";
import ReactDOM from "react-dom/client";

import awsExports from "./aws/aws-exports";
import awsGateway from "./aws/aws-gateway";

import { store } from "./app/store";

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

Amplify.configure(Object.assign(awsExports, awsGateway));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ProvideAuth>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProvideAuth>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
