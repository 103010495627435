import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface MenuItem {
  label: string;
  onClick: () => void;
}

interface MenuProps {
  options: Array<MenuItem>
}

const ITEM_HEIGHT = 30;

export default function MenuComponent({ options }: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "16ch"
          }
        }}
      >
        {options.map((option: MenuItem) => (
          <MenuItem style={{
            height: 22,
            fontSize: 14,
          }} key={option.label} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
