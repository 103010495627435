/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDateTime: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
};

export enum ActionType {
  CreateUser = 'CREATE_USER',
  DeleteUser = 'DELETE_USER',
  DisableUser = 'DISABLE_USER',
  EnableUser = 'ENABLE_USER',
  ResetPassword = 'RESET_PASSWORD'
}

export type Address = {
  __typename?: 'Address';
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  line3?: Maybe<Scalars['String']['output']>;
  line4?: Maybe<Scalars['String']['output']>;
  line5?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type Bank = {
  __typename?: 'Bank';
  accountNumber?: Maybe<Scalars['Int']['output']>;
  sortCode?: Maybe<Scalars['Int']['output']>;
};

export type Branding = {
  __typename?: 'Branding';
  id?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
};

export type BuyList = {
  __typename?: 'BuyList';
  id?: Maybe<Scalars['String']['output']>;
};

export type Charges = {
  __typename?: 'Charges';
  platformAnnualChargeId?: Maybe<Scalars['String']['output']>;
  productAnnualChargeId?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  senderEmail?: Maybe<Scalars['String']['output']>;
};

export type CreateAuditEventInput = {
  body?: InputMaybe<Scalars['AWSJSON']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  entityType: EntityType;
  eventId: Scalars['String']['input'];
  gateway: Scalars['String']['input'];
  httpStatus?: InputMaybe<Scalars['Int']['input']>;
  identifier: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['AWSJSON']['input']>;
  method: Scalars['String']['input'];
  path: Scalars['String']['input'];
  response?: InputMaybe<Scalars['AWSJSON']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIntellifloTenantInput = {
  integrationAndLegalEntitiesInput: Array<InputMaybe<IntegrationAndLegalInput>>;
  intellifloTenantInput: IntellifloTenantInput;
};

export type CreateUpdateIoConfigResponse = {
  __typename?: 'CreateUpdateIoConfigResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateUserInput = {
  attributes?: InputMaybe<Array<InputMaybe<UserAttributeInput>>>;
  customer: Scalars['String']['input'];
  email: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Customer = {
  __typename?: 'Customer';
  address?: Maybe<Address>;
  bank?: Maybe<Bank>;
  branding?: Maybe<Branding>;
  buyList?: Maybe<BuyList>;
  contact?: Maybe<Contact>;
  fcaAuthorisation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platformId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  whiteLabelType?: Maybe<Scalars['String']['output']>;
};

export type DisplayNameType = {
  __typename?: 'DisplayNameType';
  displayName?: Maybe<Scalars['String']['output']>;
};

export enum EntityType {
  Account = 'ACCOUNT',
  Activate = 'ACTIVATE',
  BankLink = 'BANK_LINK',
  Beneficiaries = 'BENEFICIARIES',
  Charge = 'CHARGE',
  Declarations = 'DECLARATIONS',
  Documents = 'DOCUMENTS',
  Employee = 'EMPLOYEE',
  GetDeclarations = 'GET_DECLARATIONS',
  Illustration = 'ILLUSTRATION',
  Investor = 'INVESTOR',
  Model = 'MODEL',
  Movement = 'MOVEMENT',
  SetModel = 'SET_MODEL',
  Transfers = 'TRANSFERS',
  Unspecified = 'UNSPECIFIED',
  Users = 'USERS'
}

export type Event = {
  __typename?: 'Event';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
  body?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  entityType: EntityType;
  eventId: Scalars['String']['output'];
  gateway: Scalars['String']['output'];
  httpStatus?: Maybe<Scalars['Int']['output']>;
  identifier: Scalars['String']['output'];
  metadata?: Maybe<Scalars['AWSJSON']['output']>;
  method: Scalars['String']['output'];
  path: Scalars['String']['output'];
  response?: Maybe<Scalars['AWSJSON']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EventConditionInput = {
  and?: InputMaybe<Array<InputMaybe<EventConditionInput>>>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  employeeKey?: InputMaybe<Scalars['String']['input']>;
  investorId?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<Array<InputMaybe<EventConditionInput>>>;
  or?: InputMaybe<Array<InputMaybe<EventConditionInput>>>;
};

export type EventFilterInput = {
  and?: InputMaybe<Array<InputMaybe<EventFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  customer?: InputMaybe<ModelStringInput>;
  entityType?: InputMaybe<ModelStringInput>;
  gateway?: InputMaybe<ModelStringInput>;
  httpStatus?: InputMaybe<ModelIntInput>;
  identifier?: InputMaybe<ModelStringInput>;
  method?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<EventFilterInput>;
  or?: InputMaybe<Array<InputMaybe<EventFilterInput>>>;
  path?: InputMaybe<ModelStringInput>;
  userId?: InputMaybe<ModelStringInput>;
};

export type EventResult = {
  __typename?: 'EventResult';
  LastEvaluatedKey?: Maybe<LastEvaluatedKey>;
  items: Array<Maybe<Event>>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export enum ExecutionStatus {
  Aborted = 'ABORTED',
  Failed = 'FAILED',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
  TimedOut = 'TIMED_OUT'
}

export type ExternalEntityMap = {
  __typename?: 'ExternalEntityMap';
  bulkValAppId?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fcaRefNo?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  internalId: Scalars['String']['output'];
  metadata: Metadata;
  providerAppId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ExternalEntityMapResult = {
  __typename?: 'ExternalEntityMapResult';
  items: Array<Maybe<ExternalEntityMap>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type GetAllIntellifloIntegrationType = {
  __typename?: 'GetAllIntellifloIntegrationType';
  items: Array<Maybe<IntellifloIntegrationType>>;
};

export type GetAllIntellifloTenantResponse = {
  __typename?: 'GetAllIntellifloTenantResponse';
  data?: Maybe<IntellifloTenantResponseType>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type GetAllIoConfigResponse = {
  __typename?: 'GetAllIoConfigResponse';
  data?: Maybe<IoConfigResponseType>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type GetIntellifloIntegrationLegalResponse = {
  __typename?: 'GetIntellifloIntegrationLegalResponse';
  data?: Maybe<Array<Maybe<IntellifloIntegrationLegalType>>>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type GetIoConfigByIdResponse = {
  __typename?: 'GetIoConfigByIdResponse';
  items: Array<Maybe<IoConfigResponse>>;
};

export type GetIpSetGroups = {
  __typename?: 'GetIpSetGroups';
  items: Array<Maybe<GetIpSetGroupsItem>>;
};

export type GetIpSetGroupsIpSet = {
  __typename?: 'GetIpSetGroupsIpSet';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GetIpSetGroupsItem = {
  __typename?: 'GetIpSetGroupsItem';
  createdAt: Scalars['String']['output'];
  dbKey: Scalars['String']['output'];
  ipSet: GetIpSetGroupsIpSet;
  isActive: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GetTenantConfigByIdResponse = {
  __typename?: 'GetTenantConfigByIdResponse';
  items: Array<Maybe<TenantConfigResponse>>;
};

export type GetTenantLegalEntitiesType = {
  __typename?: 'GetTenantLegalEntitiesType';
  items: Array<Maybe<TenantLegalEntitiesType>>;
};

export type GroupedResult = {
  __typename?: 'GroupedResult';
  badGatewayCount?: Maybe<Scalars['Int']['output']>;
  dateOrHour?: Maybe<Scalars['String']['output']>;
  successCount?: Maybe<Scalars['Int']['output']>;
  totalItems?: Maybe<Scalars['Int']['output']>;
};

export type IntegrationAndLegalInput = {
  integrationSettings: IntellifloIntegrationInput;
  legalEntity: LegalEntitiesInput;
};

export type IntegrationSettingsInput = {
  allowClientImport?: InputMaybe<Scalars['Boolean']['input']>;
  allowClientRelationshipImport?: InputMaybe<Scalars['Boolean']['input']>;
  allowDocumentUpload?: InputMaybe<Scalars['Boolean']['input']>;
  allowWealthLinkBulkValuation?: InputMaybe<Scalars['Boolean']['input']>;
  type: Scalars['String']['input'];
  wealthLinkIncomeStatement?: InputMaybe<WealthLinkIncomeStatementInput>;
  wealthLinkModelSync?: InputMaybe<WealthLinkModelSyncInput>;
};

export type IntegrationSettingsType = {
  __typename?: 'IntegrationSettingsType';
  allowClientImport?: Maybe<Scalars['Boolean']['output']>;
  allowClientRelationshipImport?: Maybe<Scalars['Boolean']['output']>;
  allowDocumentUpload?: Maybe<Scalars['Boolean']['output']>;
  allowWealthLinkBulkValuation?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
  wealthLinkIncomeStatement: WealthLinkIncomeStatementType;
  wealthLinkModelSync: WealthLinkModelSyncType;
};

export type IntellifloIntegrationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  settings: IntegrationSettingsInput;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type IntellifloIntegrationLegalType = {
  __typename?: 'IntellifloIntegrationLegalType';
  integrationSettings: IntellifloIntegrationType;
  legalEntity: LegalEntitiesType;
};

export type IntellifloIntegrationType = {
  __typename?: 'IntellifloIntegrationType';
  id: Scalars['String']['output'];
  settings: IntegrationSettingsType;
  tenantId: Scalars['String']['output'];
};

export type IntellifloTenantFilterInput = {
  and?: InputMaybe<Array<InputMaybe<IntellifloTenantFilterInput>>>;
  externalId?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelStringInput>;
  internalId?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<IntellifloTenantFilterInput>;
  or?: InputMaybe<Array<InputMaybe<IntellifloTenantFilterInput>>>;
};

export type IntellifloTenantInput = {
  bulkValAppId?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  fcaRefNo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  internalId: Scalars['String']['input'];
  metadata?: InputMaybe<MetadataInput>;
  providerAppId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type IntellifloTenantResponse = {
  __typename?: 'IntellifloTenantResponse';
  data?: Maybe<Scalars['Boolean']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type IntellifloTenantResponseType = {
  __typename?: 'IntellifloTenantResponseType';
  LastEvaluatedKey?: Maybe<LastEvaluatedKeyType>;
  intellifloTenants?: Maybe<Array<Maybe<IntellifloTenantType>>>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type IntellifloTenantType = {
  __typename?: 'IntellifloTenantType';
  bulkValAppId?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  internalId: Scalars['String']['output'];
  metadata?: Maybe<MetadataType>;
  providerAppId?: Maybe<Scalars['String']['output']>;
};

export type IoConfigInput = {
  apiEndPoint: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  appId: Scalars['String']['input'];
  authorisationToken: Scalars['String']['input'];
  hostEndPoint: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type IoConfigResponse = {
  __typename?: 'IoConfigResponse';
  apiEndPoint: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  appId: Scalars['String']['output'];
  authorisationToken: Scalars['String']['output'];
  hostEndPoint: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type IoConfigResponseType = {
  __typename?: 'IoConfigResponseType';
  LastEvaluatedKey?: Maybe<LastEvaluatedKeyType>;
  data?: Maybe<Array<Maybe<IoConfigResponse>>>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type IpSet = {
  __typename?: 'IpSet';
  ipList: Array<Maybe<List>>;
};

export type IpSetInput = {
  ipList: Array<InputMaybe<ListInput>>;
  ipSet: GetIpSetInput;
};

export type LastEvaluatedIdInput = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type LastEvaluatedKey = {
  __typename?: 'LastEvaluatedKey';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
};

export type LastEvaluatedKeyInput = {
  PK: Scalars['String']['input'];
  SK: Scalars['String']['input'];
};

export type LastEvaluatedKeyType = {
  __typename?: 'LastEvaluatedKeyType';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LastEvaluatedTenantKeyType = {
  __typename?: 'LastEvaluatedTenantKeyType';
  id: Scalars['String']['output'];
};

export type LegalEntitiesInput = {
  customerId: Scalars['String']['input'];
  fcaRefNo: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};

export type LegalEntitiesType = {
  __typename?: 'LegalEntitiesType';
  customerId: Scalars['String']['output'];
  fcaRefNo: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
};

export type LegalEntity = {
  customerId: Scalars['String']['input'];
  fcaRefNo: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};

export type Limits = {
  __typename?: 'Limits';
  maxAccountHolders?: Maybe<Scalars['Int']['output']>;
  maxAccounts?: Maybe<Scalars['Int']['output']>;
  maxInvestments?: Maybe<MaxInvestments>;
  maxTransfers?: Maybe<MaxTransfers>;
  minValues?: Maybe<MinValues>;
};

export type List = {
  __typename?: 'List';
  activeMessage?: Maybe<Scalars['String']['output']>;
  changedAt?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ip: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  owner?: Maybe<Scalars['String']['output']>;
};

export type ListInput = {
  activeMessage?: InputMaybe<Scalars['String']['input']>;
  changedAt?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ip: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  oldIP?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
};

export type MaxInvestments = {
  __typename?: 'MaxInvestments';
  initial?: Maybe<Scalars['Int']['output']>;
  initialAmendments?: Maybe<Scalars['Int']['output']>;
  regular?: Maybe<Scalars['Int']['output']>;
  regularAmendments?: Maybe<Scalars['Int']['output']>;
};

export type MaxTransfers = {
  __typename?: 'MaxTransfers';
  amendments?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  displayName?: Maybe<Scalars['String']['output']>;
};

export type MetadataInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataType = {
  __typename?: 'MetadataType';
  displayName?: Maybe<Scalars['String']['output']>;
};

export type MinValues = {
  __typename?: 'MinValues';
  initial?: Maybe<Scalars['Int']['output']>;
  regular?: Maybe<Scalars['Int']['output']>;
  topUp?: Maybe<Scalars['Int']['output']>;
  transfer?: Maybe<Scalars['Int']['output']>;
};

export type Model = {
  __typename?: 'Model';
  chargesDfmAnnualChargeId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  modelKeyByCustomerId?: Maybe<Scalars['String']['output']>;
  modelKeyDate?: Maybe<Scalars['String']['output']>;
  modelKeyId?: Maybe<Scalars['String']['output']>;
  modelRelationship?: Maybe<Scalars['String']['output']>;
  platformId?: Maybe<Scalars['String']['output']>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type Monthly = {
  __typename?: 'Monthly';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createEvent?: Maybe<Event>;
  createIntellifloTenant: IntellifloTenantResponse;
  createIoConfig: CreateUpdateIoConfigResponse;
  createUser?: Maybe<UserResponse>;
  disableUser?: Maybe<UserResponse>;
  enableUser?: Maybe<UserResponse>;
  resetUserPassword?: Maybe<UserResponse>;
  startExecution?: Maybe<StartExecutionResponse>;
  updateIntellifloTenant: IntellifloTenantResponse;
  updateIoConfig: CreateUpdateIoConfigResponse;
  updateIpSet?: Maybe<Scalars['Boolean']['output']>;
  uploadOnboardingFile: OnboardingUploadFileResponse;
};


export type MutationCreateEventArgs = {
  input: CreateAuditEventInput;
};


export type MutationCreateIntellifloTenantArgs = {
  input: CreateIntellifloTenantInput;
};


export type MutationCreateIoConfigArgs = {
  input: IoConfigInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDisableUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationEnableUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationResetUserPasswordArgs = {
  username: Scalars['String']['input'];
};


export type MutationStartExecutionArgs = {
  id: Scalars['String']['input'];
  input?: InputMaybe<Scalars['AWSJSON']['input']>;
};


export type MutationUpdateIntellifloTenantArgs = {
  input: CreateIntellifloTenantInput;
};


export type MutationUpdateIoConfigArgs = {
  input: IoConfigInput;
};


export type MutationUpdateIpSetArgs = {
  data: IpSetInput;
};


export type MutationUploadOnboardingFileArgs = {
  input: OnboardingUploadFileInput;
};

export type OnboardingUploadFileInput = {
  fileData: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  onboardingType: Scalars['String']['input'];
};

export type OnboardingUploadFileResponse = {
  __typename?: 'OnboardingUploadFileResponse';
  customerData?: Maybe<Array<Maybe<Customer>>>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  modelData?: Maybe<Array<Maybe<Model>>>;
  productData?: Maybe<Array<Maybe<Product>>>;
  statusCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type PhasePeriods = {
  __typename?: 'PhasePeriods';
  monthly?: Maybe<Monthly>;
};

export type Product = {
  __typename?: 'Product';
  bankCode?: Maybe<Scalars['String']['output']>;
  charges?: Maybe<Charges>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  limits?: Maybe<Limits>;
  name?: Maybe<Scalars['String']['output']>;
  phasePeriods?: Maybe<PhasePeriods>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getAllEvents: EventResult;
  getAllIntellifloTenant?: Maybe<GetAllIntellifloTenantResponse>;
  getAllIoConfig: GetAllIoConfigResponse;
  getAllUsers: UsersResult;
  getExternalEntityMap: ExternalEntityMapResult;
  getIntegrationAndTenantLegalEntities?: Maybe<GetIntellifloIntegrationLegalResponse>;
  getIoConfigById: GetIoConfigByIdResponse;
  getIpSet: IpSet;
  getIpSetGroups: GetIpSetGroups;
  getRequestsCount: RequestCountResult;
  getStateMachineExecutionSummary: StateMachineExecutionSummary;
  getStateMachineSummary: StateMachineSummary;
  getStateMachines: StateMachinesResponse;
  getTenantConfigById: GetTenantConfigByIdResponse;
  getTenantIntegrationsetting: GetAllIntellifloIntegrationType;
  getTenantLegalEntities: GetTenantLegalEntitiesType;
  getUser?: Maybe<UserResponse>;
  syncUser?: Maybe<UserResponse>;
};


export type QueryGetAllEventsArgs = {
  LastEvaluatedKey?: InputMaybe<LastEvaluatedKeyInput>;
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventFilterInput>;
  gateway?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAllIntellifloTenantArgs = {
  LastEvaluatedKey?: InputMaybe<LastEvaluatedIdInput>;
  filter?: InputMaybe<IntellifloTenantFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllIoConfigArgs = {
  LastEvaluatedKey?: InputMaybe<LastEvaluatedIdInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<UserSortBy>;
};


export type QueryGetExternalEntityMapArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIntegrationAndTenantLegalEntitiesArgs = {
  tenantLegalEntitiesId: Scalars['String']['input'];
};


export type QueryGetIoConfigByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIpSetArgs = {
  input?: InputMaybe<GetIpSetInput>;
};


export type QueryGetRequestsCountArgs = {
  time?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStateMachineExecutionSummaryArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStateMachineSummaryArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStateMachinesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTenantConfigByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTenantIntegrationsettingArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTenantLegalEntitiesArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  username: Scalars['String']['input'];
};


export type QuerySyncUserArgs = {
  username: Scalars['String']['input'];
};

export type RequestCountResult = {
  __typename?: 'RequestCountResult';
  badGatewayCount?: Maybe<Scalars['Int']['output']>;
  groupedResults?: Maybe<Array<Maybe<GroupedResult>>>;
  successCount?: Maybe<Scalars['Int']['output']>;
  totalItems?: Maybe<Scalars['Int']['output']>;
};

export type StartExecutionResponse = {
  __typename?: 'StartExecutionResponse';
  executionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  startDate: Scalars['AWSDateTime']['output'];
};

export type StateMachine = {
  __typename?: 'StateMachine';
  creationDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: StateMachineType;
};

export type StateMachineExecutionEvent = {
  __typename?: 'StateMachineExecutionEvent';
  detail?: Maybe<Scalars['AWSJSON']['output']>;
  id: Scalars['Int']['output'];
  previousEventId?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>;
  type: Scalars['String']['output'];
};

export type StateMachineExecutionSummary = {
  __typename?: 'StateMachineExecutionSummary';
  events?: Maybe<Array<Maybe<StateMachineExecutionEvent>>>;
  id: Scalars['String']['output'];
  input?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  status: ExecutionStatus;
  stopDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type StateMachineExecutions = {
  __typename?: 'StateMachineExecutions';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['AWSDateTime']['output'];
  status: ExecutionStatus;
  stopDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type StateMachineSummary = {
  __typename?: 'StateMachineSummary';
  definition: Scalars['String']['output'];
  executions?: Maybe<Array<Maybe<StateMachineExecutions>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum StateMachineType {
  Express = 'EXPRESS',
  Standard = 'STANDARD'
}

export type StateMachinesResponse = {
  __typename?: 'StateMachinesResponse';
  items: Array<Maybe<StateMachine>>;
};

export enum Status {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type Tenant = {
  __typename?: 'Tenant';
  customerId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  internalId?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<DisplayNameType>;
};

export type TenantConfigResponse = {
  __typename?: 'TenantConfigResponse';
  appId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TenantLegalEntitiesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  legalEntities: Array<InputMaybe<LegalEntity>>;
};

export type TenantLegalEntitiesType = {
  __typename?: 'TenantLegalEntitiesType';
  id: Scalars['String']['output'];
  legalEntities: Array<Maybe<LegalEntitiesType>>;
};

export type User = {
  __typename?: 'User';
  attributes?: Maybe<Array<Maybe<UserAttribute>>>;
  createdAt: Scalars['AWSDateTime']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  sub: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  username: Scalars['String']['output'];
};

export type UserAttribute = {
  __typename?: 'UserAttribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UserAttributeInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<InputMaybe<UserFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  customer?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  enabled?: InputMaybe<ModelBooleanInput>;
  identifier?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<UserFilterInput>;
  or?: InputMaybe<Array<InputMaybe<UserFilterInput>>>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  username?: InputMaybe<ModelStringInput>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  user?: Maybe<User>;
};

export enum UserSortBy {
  Email = 'email',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserStatusResponse = {
  __typename?: 'UserStatusResponse';
  statusCode?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['String']['output']>;
};

export type UsersResult = {
  __typename?: 'UsersResult';
  items: Array<Maybe<User>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type WealthLinkIncomeStatementInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WealthLinkIncomeStatementType = {
  __typename?: 'WealthLinkIncomeStatementType';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type WealthLinkModelSyncInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WealthLinkModelSyncType = {
  __typename?: 'WealthLinkModelSyncType';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type GetIpSetInput = {
  dbKey: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};
