import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

// Define the props for the ComposedGraph component
interface ComposedGraphProps {
  data: Array<{
    dateOrHour: string;      // X-axis data (time or date range)
    successCount: number;    // Line chart data (successful requests)
    badGatewayCount: number; // Line chart data (failed requests)
    totalItems: number;      // Bar chart data (total requests)
  }>;
}

// Define the React functional component
const ComposedGraph: React.FC<ComposedGraphProps> = ({ data }) => {
  return (
    <div style={{ width: "100%", height: 250 }}>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          {/* Grid lines for better visualization */}
          <CartesianGrid stroke="#f5f5f5" />

          {/* X-axis displays the date or hour */}
          <XAxis dataKey="dateOrHour" />

          {/* Y-axis for the counts */}
          <YAxis />

          {/* Tooltip provides detailed information on hover */}
          <Tooltip
            formatter={(value: any, name: any) => {
              switch (name) {
                case "totalItems":
                  return [value, "Total Requests"];
                case "successCount":
                  return [value, "Success Requests"];
                case "badGatewayCount":
                  return [value, "Failure Requests"];
                default:
                  return [value, name];
              }
            }}
          />

          {/* Legend with custom labels */}
          <Legend
            formatter={(value) => {
              switch (value) {
                case "totalItems":
                  return "Total Requests";
                case "successCount":
                  return "Success Request";
                case "badGatewayCount":
                  return "Failure Requests";
                default:
                  return value;
              }
            }}
          />

          {/* Bar chart for Total Requests */}
          <Bar
            dataKey="totalItems"
            name="Total Requests"  // Rename for the legend
            barSize={20}
            fill="#413ea0"  // Adjust color for total items bar
          />

          {/* Line chart for Success Requests */}
          <Line
            type="monotone"
            dataKey="successCount"
            name="Success Requests" // Rename for the legend
            stroke="#00C49F" // Green color for success count line
            dot={{ r: 4 }} // Size of the data point
            activeDot={{ r: 6 }} // Size when hovering over the data point
          />

          {/* Line chart for Failure Requests (Bad Gateway) */}
          <Line
            type="monotone"
            dataKey="badGatewayCount"
            name="Failure Requests" // Rename for the legend
            stroke="#FF0000" // Yellow color for failure requests
            dot={{ r: 4 }} // Size of the data point
            activeDot={{ r: 6 }} // Size when hovering over the data point
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ComposedGraph;
