import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Jobs from "./core/scenes/jobs";
import IpSet from "./core/scenes/ipSet";
import Events from "./core/scenes/events";
import Dashboard from "./core/scenes/dashboard";
import UsersList from "./core/scenes/users/list";
import UsersCreate from "./core/scenes/users/create";
import DocumentJobs from "./core/scenes/documentJobs";
import UsersSummary from "./core/scenes/users/summary";
import LoginWrapper from "./core/scenes/auth/LoginWrapper";
import VerifyWrapper from "./core/scenes/auth/VerifyWrapper";
import StateMachinesList from "./core/scenes/stateMachines/list";
import StateMachineSummary from "./core/scenes/stateMachines/summary";
import StateMachineExecutionSummary from "./core/scenes/stateMachines/executionSummary";

import auth from "./app/authHooks";
import Integrations from "core/scenes/intelliflo-integrations";
import AddIntegration from "core/scenes/intelliflo-integrations/add-intelliflo-tenant/AddIntellifloTenant";
import IOConfig from "core/scenes/io-config";
import AddEditIOConfig from "core/scenes/io-config/add-edit-config";
import OnboardingUpload from "core/scenes/onboarding";

const ProtectedWrapper = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = auth.useAuth();
  return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
};

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedWrapper>
            <Dashboard />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedWrapper>
            <UsersList />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines"
        element={
          <ProtectedWrapper>
            <StateMachinesList />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:id"
        element={
          <ProtectedWrapper>
            <StateMachineSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:machineId/execution/:id"
        element={
          <ProtectedWrapper>
            <StateMachineExecutionSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/users/create"
        element={
          <ProtectedWrapper>
            <UsersCreate />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/users/:id"
        element={
          <ProtectedWrapper>
            <UsersSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedWrapper>
            <Dashboard />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/jobs"
        element={
          <ProtectedWrapper>
            <Jobs />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/jobs/documents"
        element={
          <ProtectedWrapper>
            <DocumentJobs />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/events"
        element={
          <ProtectedWrapper>
            <Events />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/ip-set"
        element={
          <ProtectedWrapper>
            <IpSet />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations"
        element={
          <ProtectedWrapper>
            <Integrations />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations/new"
        element={
          <ProtectedWrapper>
            <AddIntegration />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/integrations/:id"
        element={
          <ProtectedWrapper>
            <AddIntegration />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config"
        element={
          <ProtectedWrapper>
            <IOConfig />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config/add-edit"
        element={
          <ProtectedWrapper>
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />

      <Route
        path="/io-config/add-edit/:id"
        element={
          <ProtectedWrapper>
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding"
        element={
          <ProtectedWrapper>
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding/upload"
        element={
          <ProtectedWrapper>
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />

      <Route path="/auth/login" element={<LoginWrapper />} />
      <Route path="/auth/verify" element={<VerifyWrapper />} />
    </Routes>
  );
};

export default Router;
