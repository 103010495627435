import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import {
  Box,
  Button,
  Collapse,
  FormLabel,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import Header from "../../../components/Header";
import { GetAllUsersQuery, useDisableUserMutation, useEnableUserMutation } from "./api/users.generated";

type User = NonNullable<
  NonNullable<NonNullable<GetAllUsersQuery["getAllUsers"]>["items"]>[0]
>;

const statusMap: any = {
  UNCONFIRMED: {
    label: "Unconfirmed",
    tooltip: "User has been created but not confirmed.",
  },
  CONFIRMED: {
    label: "Confirmed",
    tooltip: "User has been confirmed.",
  },
  EXTERNAL_PROVIDER: {
    label: "External Provider",
    tooltip: "User signed in with a third-party IdP.",
  },
  UNKNOWN: {
    label: "Unknown",
    tooltip: "User status isn't known.",
  },
  RESET_REQUIRED: {
    label: "Reset Required",
    tooltip:
      "User is confirmed, but the user must request a code and reset their password before they can sign in.",
  },
  FORCE_CHANGE_PASSWORD: {
    label: "Force Change Password",
    tooltip:
      "The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else.",
  },
};

const StatusLabel = ({ status }: any) => {
  if (!status) return null;
  return statusMap[status].label;
};

const StatusToolip = ({ status }: any) => {
  if (!status) return null;
  return statusMap[status].tooltip;
};

const Row: React.FC<{ row: User }> = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const colors = theme.palette;

  const [disableUser, { isLoading: isDisabling }] = useDisableUserMutation();
  const [enableUser, { isLoading: isEnabling }] = useEnableUserMutation();

  const handleDisableSubmit = async (username: any) => {
    await disableUser({ username });
  };

  const handleEnableSubmit = async (username: any) => {
    await enableUser({ username });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.username}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          {
            row?.attributes?.find((item) => item?.name === "custom:customer")
              ?.value
          }
        </TableCell>
        <TableCell>{`${row.enabled}`}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.updatedAt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: "grid",
                gridTemplateAreas: `
                  "header header"
                  "user details"
                  "user details"`,
                gridTemplateColumns: "300px 1fr",
                gap: "1rem",
                margin: "1rem",
              }}
            >
              <Box
                sx={{
                  gridArea: "header",
                }}
              >
                <Header
                  title={row ? `${row?.username}` : ""}
                  subtitle={row ? `${row?.sub}` : ""}
                />
              </Box>
              <Box
                sx={{
                  gridArea: "user",
                  backgroundColor: colors.cardBackground,
                  border: `1px solid ${colors.cardBorderColor}`,
                  p: "1rem",
                }}
              >
                <Stack>
                  {row?.enabled ? (
                    <Button
                      variant="outlined"
                      color="warning"
                      disabled={isDisabling}
                      onClick={() => handleDisableSubmit(row?.username)}
                    >
                      Disable
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="warning"
                      disabled={isEnabling}
                      onClick={() => handleEnableSubmit(row?.username)}
                    >
                      Enable
                    </Button>
                  )}
                </Stack>
              </Box>
              <Box
                sx={{
                  gridArea: "details",
                  backgroundColor: colors.cardBackground,
                  border: `1px solid ${colors.cardBorderColor}`,
                  display: "grid",
                  gridTemplateColumns: "repeat(12, 1fr)",
                  gridAutoRows: "80px",
                }}
              >
                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Email
                    </FormLabel>
                    <Tooltip
                      title="The email address of the user."
                      placement="left"
                    >
                      <Typography noWrap variant="h5">
                        {row?.email}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Username
                    </FormLabel>
                    <Tooltip
                      title="The username of the user. This is their primary login credential."
                      placement="left"
                    >
                      <Typography noWrap variant="h5">
                        {row?.username}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Created
                    </FormLabel>
                    <Tooltip
                      title="The creation date of the user."
                      placement="left"
                    >
                      <Typography noWrap variant="h5">
                        {row?.createdAt}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Updated
                    </FormLabel>
                    <Tooltip
                      title="The updated date of the user."
                      placement="left"
                    >
                      <Typography noWrap variant="h5">
                        {row?.updatedAt}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Status
                    </FormLabel>
                    <Tooltip
                      placement="left"
                      title={<StatusToolip status={row?.status} />}
                    >
                      <Typography noWrap variant="h5">
                        <StatusLabel status={row?.status} />
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Enabled
                    </FormLabel>
                    <Tooltip
                      placement="left"
                      title="Specifies whether the user is enabled."
                    >
                      <Typography noWrap variant="h5">
                        {row?.enabled ? (
                          <CheckOutlinedIcon />
                        ) : (
                          <CloseOutlinedIcon />
                        )}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>

                <Box
                  sx={{
                    gridColumn: "span 6",
                    p: "1rem",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Stack>
                    <FormLabel color="primary" filled>
                      Employee ID
                    </FormLabel>
                    <Tooltip
                      placement="left"
                      title="Specifies whether the user is enabled."
                    >
                      <Typography noWrap variant="h5">
                        {
                          row?.attributes?.find(
                            (item) => item?.name === "custom:employeeId"
                          )?.value
                        }
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
