import { Box } from "@mui/material";

import Header from "../../../components/Header";

import CreateUserForm from "./../forms/userCreateForm";

const Users = () => {
  return (
    <Box m="20px">
      <Header title="Create User" subtitle="Create a new adviser or investor" />
      <Box>
        <CreateUserForm />
      </Box>
    </Box>
  );
};

export default Users;
