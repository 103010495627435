import { paginationWrapper } from "app/utils";
import { useEffect, useState } from "react";
import { IntegrationService } from "../api";
import { InputValues } from "../intelliflo-tenant-table/filter";
import { ExternalEntityMap, IntellifloTenantFilterInput } from "app/services/types.generated";

type FilterInput = Omit<IntellifloTenantFilterInput, "and" | "or" | "not">
const useGetAllIntellifloTenant = paginationWrapper(IntegrationService.useGetAllIntellifloTenantQuery, "getAllIntellifloTenant");
const limit = 50;

function useIntellifloTenant(page: number, filters: InputValues[] | null) {

  const [intellifloTenantData, setIntellifloTenantData] = useState<Array<ExternalEntityMap>>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const { data, isFetching } = useGetAllIntellifloTenant(page)({
    limit,
    filter: filters?.length ? filters?.reduce((acc, filter) => {
      if (!filter.name) return acc;
      acc[filter.name] = {
        [filter.operation]: filter.operation === "between" ? [filter.from, filter.to] : filter.value,
      };

      return acc;
    }, {} as FilterInput) : undefined
  });

  const intellifloTenant = data?.getAllIntellifloTenant?.data?.intellifloTenants as ExternalEntityMap[];
  const lastEvaluatedKey = data?.getAllIntellifloTenant?.data?.LastEvaluatedKey;

  useEffect(() => {
    if (intellifloTenant?.length && lastEvaluatedKey && !filters) {
      setIntellifloTenantData(prevIntellifloTenant => {
        return [...prevIntellifloTenant, ...intellifloTenant];
      });

      setHasMore(true);
    } else if (intellifloTenant?.length && !filters) {
      setIntellifloTenantData(prevIntellifloTenant => {
        return [...prevIntellifloTenant, ...intellifloTenant];
      });

      setHasMore(false);
    } else if (intellifloTenant?.length && filters) {
      setIntellifloTenantData(intellifloTenant as Array<ExternalEntityMap>);
    }

  }, [intellifloTenant]);

  return { intellifloTenantData, hasMore, isFetching };
}

export default useIntellifloTenant;
