import { api, GetIpSetQuery } from "./ipSet.generated";
import {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";

export type * from "./ipSet.generated";

const generatedApi = api.enhanceEndpoints({ addTagTypes: ["IpGroups", "IpSet"] });

export type transformedGetIpSet = (GetIpSetQuery["getIpSet"]["ipList"][0] & { oldIP: string })[];
type Definitions = DefinitionsFromApi<typeof generatedApi>
type TagTypes = TagTypesFromApi<typeof generatedApi>
type UpdatedDefinitions = Omit<Definitions, "GetIpSet"> & {
  GetIpSet: OverrideResultType<Definitions["GetIpSet"], transformedGetIpSet>
}

export const enhancedApi = generatedApi.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  endpoints: {
    GetIpSet(endpoint) {
      endpoint.providesTags = ["IpSet"];
      endpoint.transformResponse = (response: GetIpSetQuery): transformedGetIpSet => {
        return response.getIpSet.ipList.map((el) => ({ ...el, oldIP: el.ip }));
      };
    },
    GetIpGroups(endpoint) {
      endpoint.providesTags = ["IpGroups"];
    },
    UpdateIpSet(endpoint) {
      endpoint.invalidatesTags = ["IpSet"];
    }
  }
});

export const {
  useGetIpSetQuery,
  useGetIpGroupsQuery,
  useUpdateIpSetMutation
} = enhancedApi;
