/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type UploadOnboardingFileMutationVariables = Types.Exact<{
  input: Types.OnboardingUploadFileInput;
}>;


export type UploadOnboardingFileMutation = { __typename?: 'Mutation', uploadOnboardingFile: { __typename?: 'OnboardingUploadFileResponse', success: boolean, statusCode: number, customerData?: Array<{ __typename?: 'Customer', name?: string, fcaAuthorisation?: string, id?: string, platformId?: string, type?: string, whiteLabelType?: string, address?: { __typename?: 'Address', line1?: string, line2?: string, line3?: string, line4?: string, line5?: string, postcode?: string }, bank?: { __typename?: 'Bank', accountNumber?: number, sortCode?: number }, branding?: { __typename?: 'Branding', id?: string, relationship?: string }, buyList?: { __typename?: 'BuyList', id?: string }, contact?: { __typename?: 'Contact', email?: string, senderEmail?: string } }>, modelData?: Array<{ __typename?: 'Model', customerId?: string, platformId?: string, modelKeyByCustomerId?: string, modelKeyId?: string, modelKeyDate?: string, modelRelationship?: string, chargesDfmAnnualChargeId?: string }>, productData?: Array<{ __typename?: 'Product', id?: string, customerId?: string, bankCode?: string, prefix?: string, name?: string, charges?: { __typename?: 'Charges', platformAnnualChargeId?: string, productAnnualChargeId?: string }, limits?: { __typename?: 'Limits', maxAccountHolders?: number, maxAccounts?: number, maxInvestments?: { __typename?: 'MaxInvestments', initial?: number, initialAmendments?: number, regular?: number, regularAmendments?: number }, maxTransfers?: { __typename?: 'MaxTransfers', amendments?: number, total?: number }, minValues?: { __typename?: 'MinValues', initial?: number, regular?: number, topUp?: number, transfer?: number } }, phasePeriods?: { __typename?: 'PhasePeriods', monthly?: { __typename?: 'Monthly', min?: number, max?: number, value?: number } } }> } };


export const UploadOnboardingFileDocument = `
    mutation UploadOnboardingFile($input: OnboardingUploadFileInput!) {
  uploadOnboardingFile(input: $input) {
    success
    statusCode
    customerData {
      name
      address {
        line1
        line2
        line3
        line4
        line5
        postcode
      }
      bank {
        accountNumber
        sortCode
      }
      branding {
        id
        relationship
      }
      buyList {
        id
      }
      contact {
        email
        senderEmail
      }
      fcaAuthorisation
      id
      platformId
      type
      whiteLabelType
    }
    modelData {
      customerId
      platformId
      modelKeyByCustomerId
      modelKeyId
      modelKeyDate
      modelRelationship
      chargesDfmAnnualChargeId
    }
    productData {
      id
      customerId
      bankCode
      prefix
      name
      charges {
        platformAnnualChargeId
        productAnnualChargeId
      }
      limits {
        maxAccountHolders
        maxAccounts
        maxInvestments {
          initial
          initialAmendments
          regular
          regularAmendments
        }
        maxTransfers {
          amendments
          total
        }
        minValues {
          initial
          regular
          topUp
          transfer
        }
      }
      phasePeriods {
        monthly {
          min
          max
          value
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UploadOnboardingFile: build.mutation<UploadOnboardingFileMutation, UploadOnboardingFileMutationVariables>({
      query: (variables) => ({ document: UploadOnboardingFileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUploadOnboardingFileMutation } = injectedRtkApi;

