import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { restBaseApi } from "./api";
import { graphqlBaseApi } from "./graphqlBaseApi";
import loginReducer from "../core/login/loginSlice";
import userReducer from "../features/users/userSlice";

export const store = configureStore({
  reducer: {
    [restBaseApi.reducerPath]: restBaseApi.reducer,
    [graphqlBaseApi.reducerPath]: graphqlBaseApi.reducer,
    login: loginReducer,
    userReducer,
  },
  middleware: (gDM) => gDM().concat(restBaseApi.middleware, graphqlBaseApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
