import "@mui/material";
import { createContext, useState, useMemo } from "react";
import { ThemeOptions, createTheme } from "@mui/material/styles";
import { Color, PaletteMode } from "@mui/material";
import { Theme } from "@emotion/react";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { mergeWith } from "lodash";

export const tokens = (mode: PaletteMode | undefined): typeof palettes["light"] => {
  return palettes[mode || "light"];
};

declare module "@mui/material/styles" {
  interface Palette extends CustomColors {
  }

  interface PaletteOptions extends CustomColors {
  }
}
type CustomColors = {
  backgroundColor: string,
  cardBackground: string,
  cardBorderColor: string,
  greenAccent: Color,
  redAccent: Color,
  blueAccent: Color,
  primaryAccent: Color,
};

const basePalette = {
  grey: {
    100: "#141414",
    200: "#292929",
    300: "#3d3d3d",
    400: "#525252",
    500: "#666666",
    600: "#858585",
    700: "#a3a3a3",
    800: "#c2c2c2",
    900: "#e0e0e0",
  },
  primaryAccent: {
    100: "#d0d1d5",
    200: "#a1a4ab",
    300: "#727681",
    400: "#434957",
    500: "#141b2d",
    600: "#101624",
    700: "#0c101b",
    800: "#080b12",
    900: "#040509",
  },
  greenAccent: {
    100: "#e1faeb",
    200: "#c3f5d7",
    300: "#a6f1c4",
    400: "#88ecb0",
    500: "#6ae79c",
    600: "#55b97d",
    700: "#408b5e",
    800: "#2a5c3e",
    900: "#152e1f",
  },
  redAccent: {
    100: "#f8dcdb",
    200: "#f1b9b7",
    300: "#e99592",
    400: "#e2726e",
    500: "#db4f4a",
    600: "#af3f3b",
    700: "#832f2c",
    800: "#58201e",
    900: "#2c100f",
  },
  blueAccent: {
    100: "#d6ebff",
    200: "#add6ff",
    300: "#85c2ff",
    400: "#5cadff",
    500: "#3399ff",
    600: "#297acc",
    700: "#1f5c99",
    800: "#143d66",
    900: "#0a1f33",
  },
};
const appPalette = {
  secondary: {
    light: basePalette.greenAccent[400],
    main: basePalette.greenAccent[500],
    dark: basePalette.greenAccent[600],
  },
  neutral: {
    dark: basePalette.grey[700],
    main: basePalette.grey[500],
    light: basePalette.grey[100],
  },
};


const palettes: { dark: PaletteOptions & CustomColors, light: PaletteOptions & CustomColors } = {
  light: mergeWith({}, basePalette, appPalette, {
    backgroundColor: "#f9f9f9",
    cardBackground: "#ffffff",
    cardBorderColor: "#e6ebf1",
    primary: {
      light: basePalette.primaryAccent[300],
      main: basePalette.primaryAccent[400],
      dark: basePalette.primaryAccent[500],
    },
    redAccent: {
      100: "#2c100f",
      200: "#58201e",
      300: "#832f2c",
      400: "#af3f3b",
      500: "#db4f4a",
      600: "#e2726e",
      700: "#e99592",
      800: "#f1b9b7",
      900: "#f8dcdb",
    },
  }),

  dark: mergeWith({}, basePalette, appPalette, {
    backgroundColor: "#0d1117",
    cardBackground: "#1d2125",
    cardBorderColor: "#112c47",
    primary: {
      main: basePalette.primaryAccent[100],
    },
    grey: {
      100: "#e0e0e0",
      200: "#c2c2c2",
      300: "#a3a3a3",
      400: "#858585",
      500: "#666666",
      600: "#525252",
      700: "#3d3d3d",
      800: "#292929",
      900: "#141414",
    },
  }),
};

// mui theme settings
export const themeSettings = (mode: PaletteMode | undefined): ThemeOptions => {
  const colors = tokens(mode);

  return {
    palette: {
      mode,
      ...colors
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    components: {
      // MuiPaper: {
      // styleOverrides: {
      //   root: {
      //     backgroundColor: `${colors.cardBackground} !important`,
      //   },
      // },
      // },
      MuiTextField: {
        styleOverrides: {
          root: {
            // borderRadius: "0px",
            // input: {
            //   color: colors.grey[100],
            // },
            // "& .MuiInputLabel-root": {
            //   color: colors.grey[100],
            // },
            "&:hover fieldset": {
              borderColor: colors?.grey?.[100],
              borderWidth: "1px",
            },
            // "&.Mui-focused fieldset": {
            //   borderColor: colors.greenAccent[600],
            //   borderWidth: "1px",
            // },
            "& .MuiOutlinedInput-root": {
              // borderRadius: "0px",
              "& fieldset": {
                // borderColor: colors.grey[100],
              },
              "&:hover fieldset": {
                borderColor: colors.blueAccent[600],
              },
              "&.Mui-focused fieldset": {
                // borderColor: colors.greenAccent[600],
              },
            },
          },
        },
      },
    },
  };
};

export const ColorModeContext: any = createContext({
  toggleColorMode: () => ({}),
});

export const useMode = () => {
  const [mode, setMode] = useState<PaletteMode | undefined>("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme: Theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
