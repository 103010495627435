import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState = {
  loggedIn: false,
  username: "",
  challenge: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.loggedIn = true;
      state.username = action.payload;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.username = "";
    },
  },
});

export const loggedInSelector = (state: RootState) => state.login.loggedIn;

export const { login, logout } = loginSlice.actions;

export default loginSlice.reducer;
