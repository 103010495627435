import { api } from "./io-config.generated";
import {
  DefinitionsFromApi,
  TagTypesFromApi
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";

export namespace IoConfigService {

  export enum IO_CONFIG_TYPES {
    IO_APP_CONFIG = "IO_APP_CONFIG"
  }

  export enum PROVIDER_TYPES {
    IO_WL = "IO-WL",
    IO_PL = "IO-PL",
  }

  const generatedApi = api.enhanceEndpoints({ addTagTypes: ["IoConfig"] });
  type Definitions = DefinitionsFromApi<typeof generatedApi>;
  type TagTypes = TagTypesFromApi<typeof generatedApi>;

  export const enhancedApi = generatedApi.enhanceEndpoints<TagTypes, Definitions>({
    endpoints: {
      GetAllIoConfig(endpoint) {
        endpoint.providesTags = ["IoConfig"];
      },
      GetIoConfigById(endpoint) {
        endpoint.providesTags = ["IoConfig"];
      },
      GetTenantConfigById(endpoint) {
        endpoint.providesTags = ["IoConfig"];
      },
      CreateIoConfig(endpoint) {
        endpoint.invalidatesTags = ["IoConfig"];
      },
      UpdateIoConfig(endpoint) {
        endpoint.invalidatesTags = ["IoConfig"];
      }
    }
  });

  export const {
    useGetAllIoConfigQuery,
    useGetIoConfigByIdQuery,
    useGetTenantConfigByIdQuery,
    useCreateIoConfigMutation,
    useUpdateIoConfigMutation
  } = enhancedApi;

}
