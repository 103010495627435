import React, { ReactNode } from "react";
import { Box } from "@mui/material";

const PageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box p="0 20px" overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
      {children}
    </Box>
  );
};

export default PageWrapper;
