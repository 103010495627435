import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel, TextField,
  Typography,
  useTheme
} from "@mui/material";

import { EventFilterInput } from "../../../app/services/types.generated";

import Row, { rows } from "./tableRow";
import Header from "../../../components/Header";
import Filter, { InputValues } from "./filter";
import { paginationWrapper } from "../../../app/utils";
import { useGetAllEventsQuery } from "./api/events.generated";
import PageWrapper from "../../../components/PageWrapper";

const limit = 15;

const TablePlaceholder: React.FC<{ children: ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={9} align="center">
      {children}
    </TableCell>
  </TableRow>
);

const useGetAllEvents = paginationWrapper(useGetAllEventsQuery, "getAllEvents");

type FilterInput = Omit<EventFilterInput, "and" | "or" | "not">
const MONTHS = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];


const EventContainer = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const date = new Date();

  const [month, setMonth] = useState<string>(String(date.getMonth() + 1));
  const [year, setYear] = useState<string>(String(date.getFullYear()));
  const [filters, setFilters] = useState<InputValues[] | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState("createdAt");

  const { data, isFetching } = useGetAllEvents(page)({
    limit,
    month: `${year}-${String(month).padStart(2, "0")}`,
    ascending: order === "asc",
    gateway: "PALLAS_GATEWAY",
    filter: filters?.length ? filters?.reduce((acc, filter) => {
      if (!filter.name) return acc;
      acc[filter.name] = {
        [filter.operation]: filter.operation === "between" ? [filter.from, filter.to] : filter.value,
      };

      return acc;
    }, {} as FilterInput) : undefined
  });

  const eventsData = data?.getAllEvents?.items;

  useEffect(() => {
    if (!data) return;
    if (!data.getAllEvents.LastEvaluatedKey && data.getAllEvents.scannedCount) {
      setCount(page * limit + data.getAllEvents.items.length);
    }
  }, [data]);

  const createSortHandler = (key: string) => {
    const isAsc = orderBy === key && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(key);
  };

  return (
    <PageWrapper>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Header
            title="Events"
            subtitle="System events, logs and details"
          />
          <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
            <TextField
              size="small"
              label="Year"
              value={year}
              onChange={(e) => {
                setPage(0);
                setCount(-1);
                setYear(e.target.value);
              }}
              inputMode="numeric"
            />
            <TextField
              select
              size="small"
              label="Month"
              value={month}
              onChange={(e) => {
                setPage(0);
                setCount(-1);
                setMonth(e.target.value);
              }}
            >
              {MONTHS.map((name, index) => (
                <MenuItem key={name} value={index + 1}>{name}</MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Filter setFilters={(filters) => {
          setPage(0);
          setCount(-1);
          setFilters(filters);
        }}/>
      </Box>
      <Box
        flexGrow={1}
        m="30px 0 0 0"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell/>
                {rows.map((row) => (
                  <TableCell
                    key={row.key}
                    sortDirection={orderBy === row.key ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === row.key}
                      direction={order}
                      onClick={() => createSortHandler(row.key)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "scroll" }}>
              {isFetching && (
                <TablePlaceholder>
                  <CircularProgress/>
                </TablePlaceholder>)
              }
              {!isFetching && !eventsData?.length && (
                <TablePlaceholder>
                  <Typography variant="h5">No Data</Typography>
                </TablePlaceholder>)}
              {!isFetching && eventsData?.map((row: any) => (
                <Row key={row?.eventId} row={row}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
          onPageChange={(_e, page) => setPage(page)}
          backIconButtonProps={isFetching ? { disabled: true } : undefined}
          nextIconButtonProps={isFetching ? { disabled: true } : undefined}
        />
      </Box>
    </PageWrapper>
  );
};

export default EventContainer;
