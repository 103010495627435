/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetAllUsersQueryVariables = Types.Exact<{
  sortBy?: Types.InputMaybe<Types.UserSortBy>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nextToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', getAllUsers: { __typename: 'UsersResult', nextToken?: string, scannedCount?: number, items: Array<{ __typename: 'User', sub: string, username: string, email: string, status?: string, enabled?: boolean, customer?: string, createdAt: any, updatedAt: any, attributes?: Array<{ __typename: 'UserAttribute', name: string, value: string }> }> } };

export type GetUserQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename: 'UserResponse', user?: { __typename: 'User', sub: string, username: string, email: string, status?: string, enabled?: boolean, customer?: string, createdAt: any, updatedAt: any, attributes?: Array<{ __typename: 'UserAttribute', name: string, value: string }> } } };

export type CreateUserMutationVariables = Types.Exact<{
  input: Types.CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename: 'UserResponse', user?: { __typename: 'User', sub: string, username: string, email: string, status?: string, enabled?: boolean, customer?: string, createdAt: any, updatedAt: any, attributes?: Array<{ __typename: 'UserAttribute', name: string, value: string }> } } };

export type EnableUserMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type EnableUserMutation = { __typename?: 'Mutation', enableUser?: { __typename: 'UserResponse', user?: { __typename: 'User', sub: string, username: string, email: string, status?: string, enabled?: boolean, customer?: string, createdAt: any, updatedAt: any, attributes?: Array<{ __typename: 'UserAttribute', name: string, value: string }> } } };

export type DisableUserMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser?: { __typename: 'UserResponse', user?: { __typename: 'User', sub: string, username: string, email: string, status?: string, enabled?: boolean, customer?: string, createdAt: any, updatedAt: any, attributes?: Array<{ __typename: 'UserAttribute', name: string, value: string }> } } };


export const GetAllUsersDocument = `
    query GetAllUsers($sortBy: UserSortBy, $filter: UserFilterInput, $limit: Int, $nextToken: String) {
  getAllUsers(
    sortBy: $sortBy
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sub
      username
      email
      status
      enabled
      customer
      attributes {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    scannedCount
    __typename
  }
}
    `;
export const GetUserDocument = `
    query GetUser($username: String!) {
  getUser(username: $username) {
    user {
      sub
      username
      email
      status
      enabled
      customer
      attributes {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
    `;
export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      sub
      username
      email
      status
      enabled
      customer
      attributes {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
    `;
export const EnableUserDocument = `
    mutation EnableUser($username: String!) {
  enableUser(username: $username) {
    user {
      sub
      username
      email
      status
      enabled
      customer
      attributes {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
    `;
export const DisableUserDocument = `
    mutation DisableUser($username: String!) {
  disableUser(username: $username) {
    user {
      sub
      username
      email
      status
      enabled
      customer
      attributes {
        name
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetAllUsers: build.query<GetAllUsersQuery, GetAllUsersQueryVariables | void>({
      query: (variables) => ({ document: GetAllUsersDocument, variables })
    }),
    GetUser: build.query<GetUserQuery, GetUserQueryVariables>({
      query: (variables) => ({ document: GetUserDocument, variables })
    }),
    CreateUser: build.mutation<CreateUserMutation, CreateUserMutationVariables>({
      query: (variables) => ({ document: CreateUserDocument, variables })
    }),
    EnableUser: build.mutation<EnableUserMutation, EnableUserMutationVariables>({
      query: (variables) => ({ document: EnableUserDocument, variables })
    }),
    DisableUser: build.mutation<DisableUserMutation, DisableUserMutationVariables>({
      query: (variables) => ({ document: DisableUserDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllUsersQuery, useLazyGetAllUsersQuery, useGetUserQuery, useLazyGetUserQuery, useCreateUserMutation, useEnableUserMutation, useDisableUserMutation } = injectedRtkApi;

