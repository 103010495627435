import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";

import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";

import Header from "../../../components/Header";
import { useNavigate } from "react-router";
import { useGetAllUsersQuery } from "./api/users.generated";

import Row from "./userRow";
import { UserSortBy } from "../../../app/services/types.generated";

const Users = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const [sortBy, setSortBy] = useState<UserSortBy>();
  const [ascending, setAscending] = useState<boolean>(true);

  const navigate = useNavigate();

  const userParams = {
    sortBy,
    ascending,
    limit: 10,
  };
  const { data, isLoading } = useGetAllUsersQuery(userParams);
  const usersData = data?.getAllUsers?.items;

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleAscendingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAscending(event.target.checked);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Users" subtitle="Manage users" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => navigate("/users/create")}
          >
            <PersonAddOutlinedIcon sx={{ mr: "10px" }} />
            Create a new user
          </Button>
        </Box>
      </Box>

      <Box display="flex">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="users-sort-by-select">Sort by:</InputLabel>
          <Select
            labelId="users-sort-by-select"
            id="users-sort-by"
            value={sortBy}
            label="Sort by"
            onChange={(event) => setSortBy(event.target.value as UserSortBy)}
          >
            {Object.values(UserSortBy).map((value) => (
              <MenuItem value={value}>
                {`${value[0].toUpperCase()}${value.slice(1)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={<Checkbox onChange={handleAscendingChange} />}
          label="Ascending"
          labelPlacement="end"
        />
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .MuiDataGrid-cell": {
            // border: "none",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData?.map((row) => (
                <Row key={row?.sub} row={row!} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <DataGrid
          rows={users}
          columns={columns}
          density="compact"
          slots={{ toolbar: GridToolbar }}
        /> */}
      </Box>
    </Box>
  );
};

export default Users;
