import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { Paths } from "components/utils/routes";
import { Box, Link } from "@mui/material";
import EditIntegration from "../edit-intelliflo-tenant/EditIntellifloTenant";
import { IntegrationService } from "../api";
import { ExternalEntityMap, IntellifloIntegrationType, TenantLegalEntitiesType } from "app/services/types.generated";
import IOConfig from "core/scenes/io-config";
import { IoConfigService } from "core/scenes/io-config/api";

const AddIntegration = () => {
  const navigate = useNavigate();
  const params = useParams();

  const isEdit = !!params?.id;

  let formValue: IntegrationService.CombinedSchemaType = {

    intellifloTenantInput: {
      externalId: "",
      internalId: "",
      metadata: {
        displayName: ""
      },
      fcaRefNo: "",
      type: "",
    },
    intellifloIntegrationLegalEntitiesInput: [{
      integrationSettings: {
        id: "",
        tenantId: "",
        settings: {
          allowClientImport: false,
          allowClientRelationshipImport: false,
          allowDocumentUpload: false,
          allowWealthLinkBulkValuation: false,
          type: "",
          wealthLinkIncomeStatement: {
            enabled: false,
          },
          wealthLinkModelSync: {
            enabled: false,
          },
        }
      },
      legalEntity: {
        customerId: "",
        fcaRefNo: "",
        groupId: "",
      }
    }]
  };

  if (isEdit && params?.id) {
    const tempId = params?.id.split("|");
    const provider = tempId[0];
    const tenantId = tempId[1];

    const { data: externalMapData, isFetching: isTenantDataLoading } = IntegrationService.useGetExternalEntityMapAllQuery({
      id: `${provider}|${tenantId}|TENANT`
    });

    const { data:IntegrationAndTenantLegalEntities , isFetching:isIntegrationAndTenantLegalEntitiesLoading } = IntegrationService.useGetIntegrationAndTenantLegalEntitiesQuery({
      tenantLegalEntitiesId: `${tenantId}|${provider}|INTEGRATION_ENTITY_RELATIONSHIP`
    });

    const { data: providerAppList, isFetching: isProviderAppListLoading } =
      IoConfigService.useGetTenantConfigByIdQuery(
        {
          id: `${provider}|${tenantId}|IO_PROVIDER_APP`
        }
      );

    const { data: bulkValAppList, isFetching: isBulkValAppListLoading } =
      IoConfigService.useGetTenantConfigByIdQuery(
        {
          id: `${provider}|${tenantId}|IO_BULK_VAL_APP`
        }
      );


    if ( isTenantDataLoading || isIntegrationAndTenantLegalEntitiesLoading || isProviderAppListLoading || isBulkValAppListLoading) {
      return <></>;
    }

    const intellifloTenant = externalMapData?.getExternalEntityMap.items[0] as ExternalEntityMap;

    const integrationLegalEntitiesData = IntegrationAndTenantLegalEntities?.getIntegrationAndTenantLegalEntities?.data || [];
    const intellifloIntegrationLegalEntitiesInput = integrationLegalEntitiesData.map((item) => ({
      integrationSettings: {
        ...item.integrationSettings
      },
      legalEntity: {
        ...item.legalEntity
      }
    }));
    const type = intellifloIntegrationLegalEntitiesInput[0]?.integrationSettings.settings.type;
    const updatedIntellifloTenant = {
      ...intellifloTenant,
      providerAppId: providerAppList?.getTenantConfigById?.items?.[0]?.appId,
      bulkValAppId: bulkValAppList?.getTenantConfigById?.items?.[0]?.appId,
      type : type
    };
    formValue = {
      intellifloTenantInput: updatedIntellifloTenant,
      intellifloIntegrationLegalEntitiesInput
    };
  }

  return (
    <Box sx={{ py: 4, px: 4 }}>
      <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
        <Link underline="hover"
          onClick={() => navigate(Paths.Integrations)}
        >
          <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to integrations
        </Link>
      </Box>

      <EditIntegration formValue={formValue} isEdit={isEdit} id={params?.id} />
    </Box>
  );
};

export default AddIntegration;
