import { Box, useTheme } from "@mui/material";
import LoginForm from "../forms/loginForm";

const LoginFormWrapper = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "450px",
          backgroundColor: colors.cardBackground,
          border: `1px solid ${colors.cardBorderColor}`,
          borderRadius: "2px",
        }}
      >
        <LoginForm />
      </Box>
    </Box>
  );
};

export default LoginFormWrapper;
