import { Box, Chip, IconButton, useTheme } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridToolbar,
} from "@mui/x-data-grid";
import jobs from "../../../mock/jobs.js";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import Header from "../../../components/Header";
import { useNavigate } from "react-router";

const AsyncJobs = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        console.log("params: ", params);

        const { generated, uploaded, failed, failedReason } = params.row;

        const props: { [key: string]: any } = {};

        if (failed) {
          props.color = "error";
          props.label = "Failed";
        } else if (generated) {
          props.color = "info";
          props.label = "Generated";
        } else if (uploaded) {
          props.color = "success";
          props.label = "Uploaded";
        } else {
          props.color = "default";
          props.label = "Pending";
        }

        return <Chip variant="outlined" {...props} />;
      },
      cellClassName: "status-column--cell",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.createdAt) {
          const dateSplit = params.row.createdAt.split("T");
          return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
        }
      },
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.updatedAt) {
          const dateSplit = params.row.updatedAt.split("T");
          return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
        }
      },
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            onClick={() => navigate(`/jobs/documents/${params.id}`)}
          >
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        );
      },
      width: 50,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Document Jobs"
        subtitle="Document job details, status, errors etc."
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .MuiDataGrid-cell": {
            // border: "none",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
            borderBottom: `1px solid ${colors.cardBorderColor}`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={jobs}
          columns={columns}
          density="compact"
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default AsyncJobs;
