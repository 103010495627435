import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useNavigate } from "react-router";
import { CreateUserMutationVariables, useCreateUserMutation } from "../users/api/users.generated";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  customer: "",
};

const userCreateSchema = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  email: yup.string().required("Email is required."),
  username: yup.string().required("Username is required."),
  customer: yup.string().required("Customer is required."),
});

const UserCreateForm = () => {
  const navigate = useNavigate();

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const [createUser, { isLoading }] = useCreateUserMutation();

  const handleFormSubmit = async (values: any) => {
    console.log("values: ", values);

    const { username, email, customer } = values;

    const attributes = [
      {
        value: values.firstName,
        name: "given_name",
      },
      {
        value: values.lastName,
        name: "family_name",
      },
    ];

    const input: CreateUserMutationVariables = {
      input: {
        username,
        email,
        attributes,
        customer,
      },
    };

    console.log("input: ", input);

    const result = await createUser(input);
    console.log("create user reuslt: ", result);

    navigate("/users");
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={userCreateSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Customer"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.customer}
                name="customer"
                error={!!touched.customer && !!errors.customer}
                helperText={!!touched.customer && errors.customer}
                sx={{
                  gridColumn: "span 4",
                }}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={!!touched.firstName && errors.firstName}
                sx={{
                  gridColumn: "span 2",
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={!!touched.lastName && errors.lastName}
                sx={{
                  gridColumn: "span 2",
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
                sx={{
                  gridColumn: "span 4",
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="username"
                label="Username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={!!touched.username && errors.username}
                sx={{
                  gridColumn: "span 4",
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={isLoading}
              >
                Create User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserCreateForm;
