/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetAllIoConfigQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  LastEvaluatedKey?: Types.InputMaybe<Types.LastEvaluatedIdInput>;
}>;


export type GetAllIoConfigQuery = { __typename?: 'Query', getAllIoConfig: { __typename?: 'GetAllIoConfigResponse', errorMessage?: string, errorType?: string, statusCode: number, success: boolean, data?: { __typename?: 'IoConfigResponseType', scannedCount?: number, LastEvaluatedKey?: { __typename?: 'LastEvaluatedKeyType', id?: string, type?: string }, data?: Array<{ __typename?: 'IoConfigResponse', apiEndPoint: string, apiKey: string, appId: string, authorisationToken: string, hostEndPoint: string, id?: string, provider: string, providerId: string, type: string }> } } };

export type GetIoConfigByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetIoConfigByIdQuery = { __typename?: 'Query', getIoConfigById: { __typename?: 'GetIoConfigByIdResponse', items: Array<{ __typename?: 'IoConfigResponse', apiEndPoint: string, apiKey: string, appId: string, authorisationToken: string, hostEndPoint: string, id?: string, provider: string, providerId: string, type: string }> } };

export type GetTenantConfigByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetTenantConfigByIdQuery = { __typename?: 'Query', getTenantConfigById: { __typename?: 'GetTenantConfigByIdResponse', items: Array<{ __typename?: 'TenantConfigResponse', id: string, appId: string, provider: string, tenantId: string, type: string }> } };

export type CreateIoConfigMutationVariables = Types.Exact<{
  input: Types.IoConfigInput;
}>;


export type CreateIoConfigMutation = { __typename?: 'Mutation', createIoConfig: { __typename?: 'CreateUpdateIoConfigResponse', data?: boolean, errorMessage?: string, errorType?: string, statusCode: number, success: boolean } };

export type UpdateIoConfigMutationVariables = Types.Exact<{
  input: Types.IoConfigInput;
}>;


export type UpdateIoConfigMutation = { __typename?: 'Mutation', updateIoConfig: { __typename?: 'CreateUpdateIoConfigResponse', data?: boolean, errorMessage?: string, errorType?: string, statusCode: number, success: boolean } };


export const GetAllIoConfigDocument = `
    query GetAllIoConfig($limit: Int, $LastEvaluatedKey: LastEvaluatedIdInput) {
  getAllIoConfig(limit: $limit, LastEvaluatedKey: $LastEvaluatedKey) {
    errorMessage
    errorType
    statusCode
    success
    data {
      scannedCount
      LastEvaluatedKey {
        id
        type
      }
      data {
        apiEndPoint
        apiKey
        appId
        authorisationToken
        hostEndPoint
        id
        provider
        providerId
        type
      }
    }
  }
}
    `;
export const GetIoConfigByIdDocument = `
    query GetIoConfigById($id: String!) {
  getIoConfigById(id: $id) {
    items {
      apiEndPoint
      apiKey
      appId
      authorisationToken
      hostEndPoint
      id
      provider
      providerId
      type
    }
  }
}
    `;
export const GetTenantConfigByIdDocument = `
    query GetTenantConfigById($id: String!) {
  getTenantConfigById(id: $id) {
    items {
      id
      appId
      provider
      tenantId
      type
    }
  }
}
    `;
export const CreateIoConfigDocument = `
    mutation CreateIoConfig($input: IoConfigInput!) {
  createIoConfig(input: $input) {
    data
    errorMessage
    errorType
    statusCode
    success
  }
}
    `;
export const UpdateIoConfigDocument = `
    mutation UpdateIoConfig($input: IoConfigInput!) {
  updateIoConfig(input: $input) {
    data
    errorMessage
    errorType
    statusCode
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetAllIoConfig: build.query<GetAllIoConfigQuery, GetAllIoConfigQueryVariables | void>({
      query: (variables) => ({ document: GetAllIoConfigDocument, variables })
    }),
    GetIoConfigById: build.query<GetIoConfigByIdQuery, GetIoConfigByIdQueryVariables>({
      query: (variables) => ({ document: GetIoConfigByIdDocument, variables })
    }),
    GetTenantConfigById: build.query<GetTenantConfigByIdQuery, GetTenantConfigByIdQueryVariables>({
      query: (variables) => ({ document: GetTenantConfigByIdDocument, variables })
    }),
    CreateIoConfig: build.mutation<CreateIoConfigMutation, CreateIoConfigMutationVariables>({
      query: (variables) => ({ document: CreateIoConfigDocument, variables })
    }),
    UpdateIoConfig: build.mutation<UpdateIoConfigMutation, UpdateIoConfigMutationVariables>({
      query: (variables) => ({ document: UpdateIoConfigDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllIoConfigQuery, useLazyGetAllIoConfigQuery, useGetIoConfigByIdQuery, useLazyGetIoConfigByIdQuery, useGetTenantConfigByIdQuery, useLazyGetTenantConfigByIdQuery, useCreateIoConfigMutation, useUpdateIoConfigMutation } = injectedRtkApi;

