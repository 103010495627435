import React, { useCallback, useEffect, useState } from "react";
import { FieldProps } from "formik";
import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import _get from "lodash/get";
import _omit from "lodash/omit";
import { debouncedOnChange } from "../utils";

type Props = TextFieldProps & FieldProps;

const TextInput: React.FC<Props> = ({
  field,
  form,
  children,
  ...TextFieldProps
}) => {
  const [innerValue, setInnerValue] = useState("");

  useEffect(() => {
    setInnerValue(field.value || "");
  }, [field.value]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value);
    debouncedOnChange(() => field.onChange(e));
  }, []);


  const touched = _get(form.touched, field.name);
  const error = _get(form.errors, field.name);
  const showError = touched && Boolean(error);
  return (
    <TextField
      fullWidth
      {..._omit(TextFieldProps, "meta")}
      id={field.name}
      name={field.name}
      value={innerValue}
      onChange={handleChange}
      onBlur={field.onBlur}
      error={showError}
      helperText={showError ? <>{error}</> : null}
    >
      {children}
    </TextField>
  );
};

export default TextInput;
