import { Box, FormLabel, Stack, Typography, useTheme } from "@mui/material";
import users from "../../../mock/users.js";

import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router";

const Users = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const navigate = useNavigate();

  const params = useParams();

  const { id } = params;

  console.log("user summary id: ", id);

  const user = users.find((user) => user.id === id);

  return (
    <Box m="20px">
      <Header
        title={user ? `${user?.given_name} ${user?.family_name}` : ""}
        subtitle={user ? `${user?.sub}` : ""}
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: `
            "user details"
            "user details"
            `,
          gridTemplateColumns: "300px 1fr",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            height: "400px",
            gridArea: "user",
            backgroundColor: colors.cardBackground,
            border: `1px solid ${colors.cardBorderColor}`,
          }}
        ></Box>
        <Box
          sx={{
            height: "400px",
            gridArea: "details",
            backgroundColor: colors.cardBackground,
            border: `1px solid ${colors.cardBorderColor}`,
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridAutoRows: "80px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Given Name
              </FormLabel>
              <Typography variant="h4">{user?.given_name}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Family Name
              </FormLabel>
              <Typography variant="h4">{user?.family_name}</Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
              textOverflow: "ellipsis",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Email
              </FormLabel>
              <Typography noWrap variant="h4">
                {user?.email}
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
              textOverflow: "ellipsis",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Username
              </FormLabel>
              <Typography noWrap variant="h4">
                {user?.username}
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
              textOverflow: "ellipsis",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Created
              </FormLabel>
              <Typography noWrap variant="h4">
                {user?.createdAt}
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              gridColumn: "span 6",
              p: "1rem",
              textOverflow: "ellipsis",
            }}
          >
            <Stack>
              <FormLabel color="primary" filled>
                Updated
              </FormLabel>
              <Typography noWrap variant="h4">
                {user?.updatedAt}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "1rem",
          p: "1rem",
          backgroundColor: colors.cardBackground,
          border: `1px solid ${colors.cardBorderColor}`,
          fontFamily: "monospace",
        }}
      >
        {user !== undefined ? (
          <pre>{JSON.stringify(user, undefined, 2)}</pre>
        ) : null}
      </Box>
    </Box>
  );
};

export default Users;
