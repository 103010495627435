import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQueryFn, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react";
import { ApiGateways, Methods } from "./api";
import { generateClient } from "aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api";

type ExtraOptions = {
  method?: Methods;
  gateway?: ApiGateways;
};

type BaseQuery = BaseQueryFn<
  { document: string, variables: any },
  unknown,
  unknown,
  ExtraOptions,
  ExtraOptions & FetchBaseQueryMeta
>;
const graphqlBaseQuery: BaseQuery = async (args) => {
  const client = generateClient();
  try {
    const result = (await client.graphql({
      query: args.document,
      variables: args.variables
    })) as GraphQLResult<unknown>;

    return { data: result.data };
  } catch (err: any) {
    console.error("graphqlBaseQuery error: ", err);
    return err;
  }
};

export const graphqlBaseApi = createApi({
  baseQuery: graphqlBaseQuery,
  endpoints: () => ({}),
  reducerPath: "graphqlBaseApi"
});

export const api = graphqlBaseApi;
