import { Box, Container } from "@mui/material";
import VerifyForm from "../forms/verifyForm";

const VerifyFormWrapper = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "450px",
        }}
      >
        <VerifyForm />
      </Box>
    </Box>
  );
};

export default VerifyFormWrapper;
